import { DateTime } from 'luxon'
import { useForm } from 'vee-validate'
import { array, mixed, object, string } from 'yup'
import type { Case, CaseTime, SaveChangeTrialDateData } from '~/repository/modules'

function useCaseChangeTrialDateFormComposable(caseIds: Array<Case['id']>) {
  const { $api } = useNuxtApp()

  const loading = ref<boolean>(false)

  const { handleSubmit, resetForm } = useForm<SaveChangeTrialDateData<CaseTime>>({
    validationSchema: object({
      case_ids: array().of(string()).min(1),
      date: string().required(),
      time: mixed().required(),
    }),
  })

  const onSubmit = handleSubmit((params) => {
    loading.value = true

    if (params.date instanceof Date)
      params.date = DateTime.fromJSDate(params.date).toISODate()!

    $api.cases.changeTrialDate.save({
      ...params,
      time: `${params.time.hours}:${params.time.minutes}:${params.time.seconds}`,
    })
      .finally(() => loading.value = false)
  })

  resetForm({
    values: {
      case_ids: caseIds,
    },
  })

  return {
    loading,
    onSubmit,
  }
}

export const useCaseChangeTrialDateForm = createSharedComposable(useCaseChangeTrialDateFormComposable)
