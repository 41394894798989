import { useForm } from 'vee-validate'
import { mixed, object, string } from 'yup'
import type { CreateLeaseDocument, Lease } from '~/repository/modules'

function createLeaseDocumentComposable(leaseId: Lease['id']) {
  const { $api } = useNuxtApp()

  const loading = ref<boolean>(false)

  const { handleSubmit } = useForm<CreateLeaseDocument>({
    validationSchema: object({
      document_type: string().required(),
      document: mixed().required(),
    }),
  })

  const onSubmit = handleSubmit((values) => {
    loading.value = true
    $api.leases.documents.create(leaseId, values)
      .finally(() => {
        loading.value = false
      })
  })

  return {
    loading,
    onSubmit,
  }
}

export const useCreateLeaseDocumentForm = createSharedComposable(createLeaseDocumentComposable)
