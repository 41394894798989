import { useForm } from 'vee-validate'
import { object, string } from 'yup'
import type { Tooltip } from '~/repository/modules'

function useCreateTooltipFormComposable() {
  const { $api } = useNuxtApp()

  const loading = ref<boolean>(false)

  const { handleSubmit, setFieldValue } = useForm<Pick<Tooltip, 'content' | 'key'>>({
    validationSchema: object({
      content: string().required(),
      key: string().required(),
    }),
  })

  const onSubmit = handleSubmit((params) => {
    loading.value = true
    $api.tooltips.create(params)
      .finally(() => loading.value = false)
  })

  return {
    loading,
    setFieldValue,
    onSubmit,
  }
}

export const useCreateTooltipForm = createSharedComposable(useCreateTooltipFormComposable)
