import { storeToRefs } from 'pinia'

export function useAuthUser() {
  const { user } = storeToRefs(useAuthStore())

  const fullName = computed(() => {
    if (user.value)
      return `${user.value.first_name} ${user.value.last_name}`

    return ''
  })

  return {
    fullName,
    user,
  }
}
