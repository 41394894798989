import { DateTime } from 'luxon'
import { useForm } from 'vee-validate'
import { object, string } from 'yup'
import type {
  Case,
  CaseLedgerItem,
  UpdateCaseLedgerItem,
} from '~/repository/modules'

function useEditCasePaymentFormComposable(
  caseId: Case['id'],
  payment: CaseLedgerItem,
) {
  const { $api } = useNuxtApp()

  const loading = ref<boolean>(false)

  const { handleSubmit, resetForm } = useForm<UpdateCaseLedgerItem>({
    validationSchema: object({
      payment_date: string().required(),
      amount: string()
        .required()
        .test(
          'is-money',
          'Amount must be a valid format e.g. 100.00',
          (value) => {
            return /^\d+(\.\d{1,2})?$/.test(value as unknown as string)
          },
        ),
      organization_chart_of_accounts_id: string().required(),
      description: string(),
    }),
  })

  const onSubmit = handleSubmit((params) => {
    loading.value = true
    $api.cases.ledger
      .updatePayment(caseId, payment.id, params)
      .finally(() => (loading.value = false))
  })

  nextTick(() => {
    resetForm({
      values: {
        payment_date: DateTime.fromISO(payment.payment_date!),
        amount: payment.amount!,
        description: payment.description,
        organization_chart_of_accounts_id:
          payment.organization_chart_of_accounts_id,
      },
    })
  })

  return {
    loading,
    onSubmit,
  }
}

export const useEditCasePaymentForm = createSharedComposable(
  useEditCasePaymentFormComposable,
)
