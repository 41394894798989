import { useForm } from 'vee-validate'
import { mixed, object } from 'yup'

function useCaseScraImportFormComposable() {
  const { $api } = useNuxtApp()
  const { $toast } = useNuxtApp()

  const loading = ref<boolean>(false)
  const error = ref<string | null>(null)

  const { handleSubmit } = useForm<{ file: File }>({
    validationSchema: object({
      file: mixed().required(),
    }),
  })

  const onSubmit = handleSubmit((params) => {
    loading.value = true
    error.value = null

    $api.cases.importScra(params)
      .then(() => {
        $toast.addToast({
          title: 'Success',
          message: 'The file has been imported successfully.',
          type: 'success',
        })
      })
      .catch((e) => {
        const message
          = (e.response?.data as { message?: string })?.message
          || e.message
          || 'An unknown error occurred'
        error.value = message
      })
      .finally(() => loading.value = false)
  })

  return {
    // variables
    loading,
    error,

    // methods
    onSubmit,
  }
}

export const useCaseScraImportForm = createSharedComposable(useCaseScraImportFormComposable)
