<script lang="ts" setup>
import SidebarLink from './SidebarLink.vue'
import type { Navigation, NavigationType } from '~/sites/dashboard/types/sidebar'

defineProps<{
  item: Navigation
}>()

const titleType: NavigationType = 'title'
</script>

<template>
  <div v-if="item.type === titleType" class="text-xs font-semibold leading-6 text-gray-400 mt-3 uppercase">
    {{ item.name }}
  </div>
  <SidebarLink
    v-else
    :id="item.id"
    :name="item.name"
    :type="item.type"
    :href="item.href"
    :icon="item.icon"
    :initial="item.initial"
    :children="item.children"
    :badge="item.badge"
    :permission="item.permission"
  />
</template>
