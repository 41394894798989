import { useForm } from 'vee-validate'
import { object, string } from 'yup'
import type { Case, CaseNote, CreateCaseNote } from '~/repository/modules'

function useEditCaseNotesComposable(caseId: Case['id']) {
  const { $api } = useNuxtApp()

  const loading = ref<boolean>(false)
  const notesLoading = ref<boolean>(false)
  const notes = ref<CaseNote[]>([])

  const { handleSubmit, setFieldError } = useForm<CreateCaseNote>({
    validationSchema: object({
      note: string().required(),
    }),
  })

  const onSubmit = handleSubmit((values) => {
    loading.value = true
    $api.cases.notes.create(caseId, values)
      .catch((e) => {
        if (isAxiosFormError(e))
          setFormErrorsFromRequest(e, setFieldError)
      })
      .finally(() => loading.value = false)
  })

  notesLoading.value = true
  $api.cases.notes.list(caseId, {})
    .then((response) => {
      notes.value = response.data
    }).finally(() => notesLoading.value = false)

  return {
    loading,
    notesLoading,
    notes,
    onSubmit,
  }
}

export const useEditCaseNotesForm = createSharedComposable(useEditCaseNotesComposable)
