import {
  BookOpenIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentTextIcon,
  HandRaisedIcon,
  HomeIcon,
  PresentationChartLineIcon,
  FolderOpenIcon,
} from '@heroicons/vue/24/outline'
import {QuestionMarkCircleIcon} from '@heroicons/vue/24/solid'
import {defineStore, storeToRefs} from 'pinia'
import type {WorkflowStep} from '~/repository/modules'
import type {Navigation} from '~/sites/dashboard/types/sidebar'
import {VBadge} from '#components'

const {userCan} = useAuthStore()
const {organization} = storeToRefs(useOrganizationStore())

let idCounter = 1

const SIDEBAR_ITEM_IDS = {
  DASHBOARD: idCounter++,
  MANAGE: idCounter++,
  LEASES: idCounter++,
  RENT_CASES: idCounter++,
  DOCUMENTS: idCounter++,
  CASES_REQUIRING_PROCESSING: idCounter++,
  OTHER_CASES: idCounter++,
  REPORTS: idCounter++, // error
  ADMIN: idCounter++, // error
  OTHER: idCounter++,
  INTEGRATIONS: idCounter++,
  INTERNAL: idCounter++,
  TOOLTIPS: idCounter++,
  CASES_REPORT: idCounter++, // error
  ACTIVE_CASES_REPORT: idCounter++, // error
  PROPERTIES: idCounter++, // error
  INTEGRATION_LOGS: idCounter++, // error
  COMMUNITIES: idCounter++, // error
  ERROR_LOGS: idCounter++, // error
  BUILDINGS: idCounter++, // error
  ORGANIZATION: idCounter++, // error
  UNITS: idCounter++,
  LOGS: idCounter++,
  ORGANIZATION_USERS: idCounter++,
  ORGANIZATION_SETTINGS: idCounter++,
}

export const useSidebarStore = defineStore('sidebar', () => {
  const {workflow} = storeToRefs(useWorkflowStore())
  const sidebarOpen = useLocalStorage<boolean>('sidebar.open', true)

  const {isSuperAdmin} = storeToRefs(useAuthStore())

  const rentCases = computed(() => {
    const casesRequiringProcessing: Navigation = {
      id: SIDEBAR_ITEM_IDS.CASES_REQUIRING_PROCESSING,
      type: 'icon',
      name: 'Cases For Processing',
      href: '/cases/processing-queue',
      icon: HandRaisedIcon,
      badge: () => h(VBadge, {type: 'info'}, () => workflow.value?.cases_awaiting_processing || 0),
      defaultOpenKey: 'sidebar.cases-requiring-processing',
      permission: 'cases:process-transition',
    }

    const rentCasesNavigation: Navigation = {
      id: SIDEBAR_ITEM_IDS.RENT_CASES,
      type: 'icon',
      name: 'Rent Cases',
      href: '#',
      icon: BookOpenIcon,
      children: [],
      defaultOpenKey: 'sidebar.rentcases',
    }

    if (workflow.value?.steps) {
      const children = useMap(
        useFilter(workflow.value?.steps, (step: WorkflowStep) => {
          const regex = /Filing Queue*/g
          if (regex.test(step.name))
            return step.name.length < 13

          return true
        }),
        (step: WorkflowStep, idx: number) => {
          return {
            id: `${SIDEBAR_ITEM_IDS.RENT_CASES}-${idx}`,
            type: 'icon',
            name: step.name,
            href: `/cases/step/${step.id}`,
            badge: () => h(VBadge, {type: 'info'}, () => step.case_count),
          } as Navigation
        },
      )

      if (children)
        rentCasesNavigation.children = children
    }

    const rentCasesNav = []
    if (organization.value?.settings?.general?.multi_step_processing) {
      rentCasesNav.push(casesRequiringProcessing)
    }
    rentCasesNav.push(rentCasesNavigation)
    return rentCasesNav
  })

  // TODO - removing this for now, but will need added back in later, so leaving it here for reference
  // const otherCases = computed(() => {
  //   const navigation: Navigation = {
  //     id: SIDEBAR_ITEM_IDS.OTHER_CASES,
  //     type: 'icon',
  //     name: 'Other Cases',
  //     href: '#',
  //     defaultOpenKey: 'sidebar.othercases',
  //     icon: DocumentDuplicateIcon,
  //     children: [],
  //   }

  //   if (workflow.value?.steps) {
  //     const children = useMap(
  //       useFilter(workflow.value?.steps, (step: WorkflowStep) => {
  //         const regex = /Filing Queue*/g
  //         if (regex.test(step.name))
  //           return step.name.length > 12

  //         return false
  //       }),
  //       (step: WorkflowStep, idx: number) => {
  //         return {
  //           id: `${SIDEBAR_ITEM_IDS.OTHER_CASES}-${idx}`,
  //           name: step.name.substring(14, step.name.length),
  //           type: 'icon',
  //           href: `/cases/step/${step.id}`,
  //           badge: () => h(VBadge, { type: 'info' }, () => step.case_count),
  //         } as Navigation
  //       },
  //     )

  //     if (children)
  //       navigation.children = children
  //   }

  //   return navigation
  // })

  const navigationItems = computed(() => {
    const navigation: Navigation[] = [
      {
        id: SIDEBAR_ITEM_IDS.DASHBOARD,
        type: 'icon',
        name: 'Dashboard',
        href: '/dashboard',
        icon: PresentationChartLineIcon,
      },
      {
        id: SIDEBAR_ITEM_IDS.MANAGE,
        type: 'title',
        name: 'Manage',
        href: '#',
      },
      {
        id: SIDEBAR_ITEM_IDS.PROPERTIES,
        type: 'icon',
        name: 'Properties',
        defaultOpenKey: 'sidebar.properties',
        icon: HomeIcon,
        href: '#',
        children: [
          {
            id: SIDEBAR_ITEM_IDS.COMMUNITIES,
            name: 'Communities',
            type: 'icon',
            href: '/communities',
          },
          {
            id: SIDEBAR_ITEM_IDS.BUILDINGS,
            name: 'Buildings',
            type: 'icon',
            href: '/buildings',
          },
          {
            id: SIDEBAR_ITEM_IDS.UNITS,
            name: 'Units',
            type: 'icon',
            href: '/units',
          },
        ],
      },
      {
        id: SIDEBAR_ITEM_IDS.LEASES,
        type: 'icon',
        name: 'Leases',
        href: '/leases',
        icon: DocumentTextIcon,
      }
    ]

    navigation.push(...rentCases.value)

    // TODO - removing this for now, but will need added back in later, so leaving it here for reference
    // navigation.push(otherCases.value)

    navigation.push(
      {
        id: SIDEBAR_ITEM_IDS.DOCUMENTS,
        type: 'icon',
        name: 'Documents',
        href: '/documents',
        icon: FolderOpenIcon,
        permission: 'reports:read',
      },
      {
        id: SIDEBAR_ITEM_IDS.REPORTS,
        type: 'icon',
        name: 'Reports',
        defaultOpenKey: 'sidebar.reports',
        href: '#',
        icon: ChartPieIcon,
        permission: 'reports:read',
        children: [
          {
            id: SIDEBAR_ITEM_IDS.ACTIVE_CASES_REPORT,
            name: 'Active Cases Report',
            type: 'download',
            href: '/active-case-reports',
            permission: 'reports:read',
          },
        ],
      }
    )

    const adminItems: Navigation[] = []

    if (userCan('integrations:read')) {
      adminItems.push({
        id: SIDEBAR_ITEM_IDS.INTEGRATIONS,
        type: 'icon',
        name: 'Integrations',
        href: '/integrations',
        icon: Cog6ToothIcon,
      })
    }

    if (userCan('users:read')) {
      adminItems.push({
        id: SIDEBAR_ITEM_IDS.ORGANIZATION_USERS,
        name: 'Users',
        type: 'icon',
        href: '/users',
      })
    }

    if (userCan('logs:read')) {
      adminItems.push({
        id: SIDEBAR_ITEM_IDS.LOGS,
        type: 'icon',
        name: 'Logs',
        href: '',
        children: [
          {
            id: SIDEBAR_ITEM_IDS.INTEGRATION_LOGS,
            name: 'Integration',
            type: 'icon',
            href: '/logs/integration',
          },
          {
            id: SIDEBAR_ITEM_IDS.ERROR_LOGS,
            name: 'Error',
            type: 'icon',
            href: '/logs/error',
          },
        ],
      })
    }

    if (userCan('organization:update')) {
      adminItems.push({
        id: SIDEBAR_ITEM_IDS.ORGANIZATION,
        name: 'Organization',
        type: 'icon',
        href: '',
        children: [
          {
            id: SIDEBAR_ITEM_IDS.ORGANIZATION_SETTINGS,
            name: 'Settings',
            type: 'icon',
            href: '/organization/settings',
          },
        ],
      })
    }

    if (adminItems.length > 0) {
      navigation.push({
        id: SIDEBAR_ITEM_IDS.OTHER,
        type: 'title',
        name: 'OTHER',
        href: '',
      })

      navigation.push({
        id: SIDEBAR_ITEM_IDS.ADMIN,
        type: 'icon',
        name: 'Admin',
        href: '#',
        icon: Cog6ToothIcon,
        defaultOpenKey: 'sidebar.admin',
        children: [...adminItems],
      })
    }

    if (isSuperAdmin.value) {
      navigation.push({
        id: SIDEBAR_ITEM_IDS.INTERNAL,
        type: 'title',
        name: 'Internal',
        href: '#',
      })
      navigation.push({
        id: SIDEBAR_ITEM_IDS.TOOLTIPS,
        type: 'icon',
        name: 'Tooltips',
        href: '/tooltips',
        icon: QuestionMarkCircleIcon,
      })
    }

    return navigation
  })

  return {
    navigationItems,
    sidebarOpen,
  }
})
