import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useMessageStore = defineStore('message', () => {
  const message = ref<Message>({
    messages: null,
    status: null,
    type: 'info',
  })

  const setMessage = (messages: Array<string> | null | undefined, status: number, type: 'warning' | 'danger' | 'success' | 'info') => {
    message.value.messages = messages
    message.value.status = status
    message.value.type = type
  }

  const resetMessage = () => {
    message.value.messages = null
    message.value.status = null
    message.value.type = 'info'
  }
  return {
    message,
    setMessage,
    resetMessage,
  }
})
