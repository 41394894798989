import { useForm } from 'vee-validate'
import { object, string } from 'yup'
import type { CreateLeaseFee, Lease } from '~/repository/modules'

function useCreateLeaseFeeComposable(leaseId: Lease['id']) {
  const { $api } = useNuxtApp()

  const loading = ref<boolean>(false)

  const { handleSubmit, setFieldValue } = useForm<CreateLeaseFee>({
    validationSchema: object({
      fee_date: string().required(),
      service_from: string().required(),
      amount: string().required().test(
        'is-money',
        'Amount must be a valid format e.g. 100.00',
        (value) => {
          return /^\d+(\.\d{1,2})?$/.test(value as unknown as string)
        },
      ),
      account: string().required(),
      note: string().nullable(),
    }),
  })

  const onSubmit = handleSubmit((values) => {
    loading.value = true
    $api.leases.transactions.createFee(leaseId, values)
      .finally(() => loading.value = false)
  })

  return {
    loading,
    setFieldValue,
    onSubmit,
  }
}

export const useCreateLeaseFeeForm = createSharedComposable(useCreateLeaseFeeComposable)
