import { DateTime } from 'luxon'
import { useForm } from 'vee-validate'
import {number, object, string} from 'yup'
import type { DocketWizardData, DocketWizardItem, SaveDocketWizardData, Unit, WorkflowStep } from '~/repository/modules'

function useCaseDocketWizardFormComposable(step: WorkflowStep) {
  const { $api } = useNuxtApp()

  const loadingBatchData = ref<boolean>(false)
  const loading = ref<boolean>(false)
  const docketData = ref<DocketWizardData>()
  const availableDays = ref<DocketWizardItem['days']>([])
  const availableTimes = ref<DocketWizardItem['times']>([])
  const availableTimesSelect = ref<SelectItem[]>([])
  const cases = ref <SaveDocketWizardData<{ unit: Unit }>>([])

  const wizardData = reactive<{
    batch_id: string | undefined
    prefix: string | undefined
    court_date: string | undefined
    court_time: string | undefined
    case_number: number | undefined
  }>({
    batch_id: undefined,
    prefix: undefined,
    court_date: undefined,
    court_time: undefined,
    case_number: undefined,
  })

  const stepper = useStepper({
    batch: {
      name: 'Select Batch ID',
      validationSchema: object({
        batch_id: string().required(),
      }),
      onSubmit: async (values: { batch_id: string }) => {
        wizardData.batch_id = values.batch_id
        const item = docketData.value?.docket_wizard_data[values.batch_id]

        if (item) {
          wizardData.prefix = item.case_prefix
          availableDays.value = item.days
          availableTimes.value = item.times
        }

        stepper.goToNext()
      },
    },
    details: {
      name: 'Docket Details',
      validationSchema: object({
        court_date: string().required('Court Date is required'),
        court_time: string().required('Court Time is required'),
        prefix: string().nullable(),
        case_number: number().positive().required('Case Number is required').typeError('Case Number must be a valid number'),
      }),
      onSubmit: async (values: { prefix: string | null; court_date: string; court_time: string; case_number: number }) => {
        cases.value = []
        const date = new Date(values.court_date)
        const formattedDate = date.toISOString().split('T')[0]

        wizardData.case_number = values.case_number
        wizardData.court_date = formattedDate
        wizardData.court_time = values.court_time

        const startingCaseNumberLength = values.case_number.toString().length

        if (values.prefix && values.prefix.length)
          wizardData.prefix = values.prefix

        loading.value = true
        $api.cases.listByBatchId({ batch_id: wizardData.batch_id! })
          .then((response) => {
            cases.value = []

            response.data.forEach((_case, index: number) => {
              const tempCaseNumber = String(Number(wizardData.case_number) + index).padStart(startingCaseNumberLength, '0')

              cases.value.push({
                case_id: _case.id,
                unit: _case.unit!,
                case_number: `${wizardData.prefix}${tempCaseNumber}`,
                prefix: wizardData.prefix!,
                court_date: wizardData.court_date!,
                court_time: wizardData.court_time!,
              })
            })

            stepper.goToNext()
          })
          .finally(() => loading.value = false)
      },
    },
    review: {
      name: 'Review Cases',
      validationSchema: object({}),
      onSubmit: async () => {
        loading.value = true
        $api.cases.docketWizard.save(cases.value.map(_case => useOmit(_case, 'unit')))
          .finally(() => loading.value = false)
      },
    },
  })

  const currentValidation = computed(() => {
    return stepper.current.value.validationSchema ?? {}
  })

  const { handleSubmit, setFieldValue, errors } = useForm({
    validationSchema: currentValidation,
  })

  const onSubmit = handleSubmit((params) => {
    const currentStep = stepper.current

    if (currentStep.value.onSubmit) {
      // @ts-expect-error ignore
      currentStep.value.onSubmit(params)
    }
  })

  const batchIdSelect = computed<SelectItem[]>(() => {
    const items = [{ text: 'Select Batch Number', value: '' }]

    if (docketData.value) {
      for (const key in docketData.value.docket_wizard_data) {
        items.push({
          text: key,
          value: key,
        })
      }
    }

    return items
  })

  const disabledDaysList = computed(() => {
    if (availableDays.value.length)
      return [0, 1, 2, 3, 4, 5, 6].filter(item => !availableDays.value.map(Number).includes(item))

    return []
  })

  const setCourtTimes = (val: Date) => {
    setFieldValue('court_time', '')
    availableTimesSelect.value = []
    const dayIndex = DateTime.fromJSDate(val).weekday

    if (availableTimes.value[dayIndex]) {
      availableTimes.value[dayIndex].forEach((value, idx) => {
        const time = value.split(':')
        availableTimesSelect.value.push({
          text: useDateFormat(new Date(0, 0, 0, Number(time[0]), Number(time[1])), 'hh:mm A').value,
          value,
        })
      })
    }
  }

  const previousStep = () => {
    stepper.goToPrevious()

    if (stepper.isCurrent('batch')) {
      setFieldValue('batch_id', wizardData.batch_id)
    }
    else if (stepper.isCurrent('details')) {
      nextTick(() => {
        setFieldValue('court_date', wizardData.court_date)
        setFieldValue('court_time', wizardData.court_time)
        setFieldValue('case_number', wizardData.case_number)
        setFieldValue('prefix', wizardData.prefix)
      })
    }
  }

  loadingBatchData.value = true
  $api.cases.docketWizard.data(step.id)
    .then((response) => {
      docketData.value = response.data
    })
    .finally(() => loadingBatchData.value = false)

  return {
    loadingBatchData,
    loading,
    stepper,
    batchIdSelect,
    disabledDaysList,
    availableTimesSelect,
    cases,
    errors,
    previousStep,
    setCourtTimes,
    onSubmit,
  }
}

export const useCaseDocketWizardForm = createSharedComposable(useCaseDocketWizardFormComposable)
