import { defineStore } from 'pinia'
import type { Types } from 'ably'
import Ably from 'ably'
import { useAuthUser } from '~/sites/dashboard/composables/users/useAuthUser'

/**
 * Websocket Store
 *
 * Manages real-time communication across multiple browser tabs using Ably websockets.
 *
 * Key features:
 * - Ensures only one active websocket connection exists across all tabs
 * - Maintains connection in the most recently focused tab
 * - Coordinates tab visibility and connection state across tabs
 * - Handles graceful connection cleanup when tabs are closed
 */
export const useWebsocketStore = defineStore('websockets', () => {
  const { user } = useAuthUser()
  const runtimeConfig = useRuntimeConfig()
  const { casesLocalChannel, handleCaseUpdate } = useCasesStore()
  const { workflowLocalChannel, handleWorkflowUpdate } = useWorkflowStore()
  const { notificationsLocalChannel, handleRealtimeNotification } = useNotificationsStore()

  // Core websocket state
  const client = ref<Types.RealtimePromise | null>(null)
  const isTabVisible = ref<boolean>(false)
  const activeTabsCount = ref(1)
  const visibleTabsCount = ref(1)
  const openConnectionCount = ref(0)

  // Tab coordination
  const tabId = Math.random().toString(36).substring(2, 9)
  const activeTabsSet = new Set([tabId])
  const localUpdateChannel = new BroadcastChannel('tab-count')

  // Channel references for different types of real-time updates
  const userChannel = ref<Types.RealtimeChannelPromise | null>(null)
  const workflowChannel = ref<Types.RealtimeChannelPromise | null>(null)
  const casesChannel = ref<Types.RealtimeChannelPromise | null>(null)

  /**
   * Initializes websocket channels for user-specific, workflow, and case updates.
   * Each channel is scoped to the user's organization and handles updates via BroadcastChannel
   * to ensure all tabs receive updates regardless of which tab has the active connection.
   */
  const setupChannels = () => {
    if (client.value) {
      userChannel.value = client.value.channels.get(`private:user-${user.value?.id}.org-${user.value?.organization_id}`)
      workflowChannel.value = client.value.channels.get(`private:workflow.user-${user?.value?.id}.org-${user.value?.organization_id}`)
      casesChannel.value = client.value.channels.get(`private:cases.org-${user.value?.organization_id}`)

      // Subscribe to channels and proxy notifications to relevant store handlers
      userChannel.value.subscribe((messageData) => {
        notificationsLocalChannel.postMessage({type: 'user-notification', data: messageData})
        handleRealtimeNotification(messageData)
      })
      casesChannel.value.subscribe((messageData) => {
        casesLocalChannel.postMessage({ type: 'case-update', data: messageData })
        handleCaseUpdate(messageData)
      })
      workflowChannel.value.subscribe((messageData) => {
        workflowLocalChannel.postMessage({ type: 'workflow-updated', data: messageData })
        handleWorkflowUpdate(messageData)
      })
    }
  }

  /**
   * Safely closes the Ably connection and cleans up channel references.
   * Decrements the connection count to maintain accurate connection tracking.
   */
  const closeAblyConnection = () => {
    if (client.value) {
      try {
        client.value.close()
        openConnectionCount.value--
        client.value = null
        userChannel.value = null
        workflowChannel.value = null
        casesChannel.value = null
      }
      catch (error) {
        console.warn('Error closing websocket connection:', error)
      }
    }
  }

  /**
   * Creates a new Ably client with the user's credentials.
   * Ensures clean slate by closing any existing connections before creating new ones.
   * @returns Promise<Types.RealtimePromise | null>
   */
  const createAblyClient = () => {
    if (user.value && runtimeConfig.public.ablyKey) {
      // Always clean up existing connections first
      if (client.value || openConnectionCount.value > 0)
        closeAblyConnection()

      // Small delay to ensure clean slate
      return new Promise<Types.RealtimePromise | null>((resolve) => {
        nextTick(() => {
          const newClient = new Ably.Realtime.Promise({
            key: runtimeConfig.public.ablyKey,
            clientId: user.value?.email,
          })

          openConnectionCount.value++
          resolve(newClient)
        })
      })
    }
    return null
  }

  /**
   * Safety check to prevent multiple concurrent connections.
   * If multiple connections are detected, forces a reset to ensure only one remains.
   */
  const ensureSingleConnection = () => {
    if (openConnectionCount.value > 1) {
      console.warn('Multiple connections detected, resetting...')
      closeAblyConnection()
      openConnectionCount.value = 0
    }
  }

  /**
   * Handles cross-tab communication via BroadcastChannel.
   * Message types:
   * - force-close: Forces other tabs to close their connections
   * - tab-focused: Notifies when a tab gains focus
   * - tab-blurred: Notifies when a tab loses focus
   * - tab-opened: Announces new tab creation
   * - tab-ack: Acknowledges tab presence
   * - tab-closed: Announces tab closure
   */
  localUpdateChannel.onmessage = (event) => {
    const { type, id, fromId } = event.data
    switch (type) {
      case 'force-close':
        if (fromId !== tabId && openConnectionCount.value > 0)
          closeAblyConnection()

        break

      case 'tab-focused':
        if (id !== tabId)
          closeAblyConnection()

        break

      case 'tab-blurred':
        activeTabsSet.delete(id)
        visibleTabsCount.value--

        // Close the connection only if there are other tabs in focus
        if (visibleTabsCount.value === 0 && openConnectionCount.value === 1) {
          // Do not close connection if this is the last visible tab
          return
        }

        // If there are still focused tabs, close this one
        closeAblyConnection()
        break

      case 'tab-opened':
        activeTabsSet.add(id)
        localUpdateChannel.postMessage({ type: 'tab-ack', id: tabId })
        break

      case 'tab-ack':
        activeTabsSet.add(id)
        break

      case 'tab-closed':
        activeTabsSet.delete(id)
        break
    }
    activeTabsCount.value = activeTabsSet.size
  }

  // Initialize connection if this is the visible tab
  if (document.hasFocus()) {
    isTabVisible.value = true
    nextTick(async () => {
      client.value = await createAblyClient()
      if (client.value)
        setupChannels()
    })
  }

  localUpdateChannel.postMessage({ type: 'tab-opened', id: tabId })

  const handleVisibilityChange = () => {
    const newVisibilityState = document.hasFocus()
    const previousVisibleCount = visibleTabsCount.value

    if (newVisibilityState) {
      visibleTabsCount.value++
      isTabVisible.value = true

      // Only create connection if we're the first visible tab and no connections exist
      if (previousVisibleCount === 0 && openConnectionCount.value === 0) {
        nextTick(async () => {
          client.value = await createAblyClient()
          if (client.value)
            setupChannels()
        })
      }
    }
    else {
      visibleTabsCount.value--
      isTabVisible.value = false

      // Only close if we're not the last visible tab
      if (visibleTabsCount.value > 0)
        closeAblyConnection()
    }
  }

  const handleFocus = () => {
    handleVisibilityChange()
    activeTabsSet.add(tabId)

    nextTick(async () => {
      // First notify other tabs
      localUpdateChannel.postMessage({ type: 'force-close', fromId: tabId })

      // Wait briefly for other connections to close
      await new Promise(resolve => setTimeout(resolve, 50))

      if (document.hasFocus() && isTabVisible.value) {
        client.value = await createAblyClient()
        if (client.value)
          setupChannels()
      }
    })
  }

  const handleBlur = () => {
    handleVisibilityChange()
    activeTabsSet.delete(tabId)
    localUpdateChannel.postMessage({ type: 'tab-blurred', id: tabId })

    // Only close if there's another visible tab
    if (visibleTabsCount.value > 0)
      closeAblyConnection()
  }

  const handleBeforeUnload = () => {
    localUpdateChannel.postMessage({ type: 'tab-closed', id: tabId })
    closeAblyConnection()
  }

  document.addEventListener('visibilitychange', handleVisibilityChange)
  window.addEventListener('focus', handleFocus)
  window.addEventListener('blur', handleBlur)
  window.addEventListener('beforeunload', handleBeforeUnload)

  // Add periodic connection check
  const connectionCheckInterval = setInterval(() => {
    ensureSingleConnection()
  }, 5000)

  /**
   * Cleanup function to remove event listeners and close connections
   * when the component is unmounted or tab is closed.
   */
  onUnmounted(() => {
    document.removeEventListener('visibilitychange', handleVisibilityChange)
    window.removeEventListener('beforeunload', handleBeforeUnload)
    window.removeEventListener('focus', handleFocus)
    window.removeEventListener('blur', handleBlur)

    localUpdateChannel.close()

    // Ensure we only close the connection when necessary
    if (visibleTabsCount.value > 0)
      closeAblyConnection()

    clearInterval(connectionCheckInterval)
  })

  return {
    client,
    userChannel,
    workflowChannel,
    casesChannel,
    isTabVisible,
    activeTabsCount,
  }
})
