import type { AllowedCategory, CaseSummary, Judge, WorkflowStep } from '~/repository/modules'
import type { IAdjudication, IAdjudicationCase } from '~/repository/modules/cases/caseAdjudication'

export const COURT_COST_CATEGORY = 'Court Cost'
export const ATTORNEY_FEES_CATEGORY = 'Attorney Fees'

function useCaseAdjudicationWizardFormComposable(
  step: WorkflowStep,
  cases: CaseSummary[] = [],
  onComplete: (data: { message: string }) => void,
) {
  const { $api } = useNuxtApp()

  const savingAdjudication = ref<boolean>(false)
  const errors = ref<null | any>(null)

  const judges = ref<{
    loading: boolean
    data: Judge[]
  }>({
    loading: false,
    data: [],
  })
  const allowedCategories = ref<{
    loading: boolean
    data: AllowedCategory[]
  }>({
    loading: false,
    data: [],
  })

  const wizardData = reactive<IAdjudication>({
    move_to_appropriate_disposition: true,
    judge_id: '',
    judge_feedback: '',
    judges_allowed_category: [],
    cases: cases
      .filter(c => c.disposition)
      .map(c => ({
        id: c.id,
        case_number: c.case_number,
        disposition: c.disposition,
        resident_appeared: c.resident_appeared ?? false,
        court_credit: c.court_credit ?? '0',
        disposition_manually_adjusted: false,
        computed_ftpr_amended_value: c.computed_ftpr_amended_value ?? '0',
        computed_ftpr_value: c.computed_ftpr_value ?? '0',
        disposition_value: c.disposition_value ?? c.computed_ftpr_amended_value ?? c.computed_ftpr_value ?? '0',
        stipulations: c.stipulations ?? [],
      })),
  })

  const stepper = useStepper({
    verifyEntries: {
      name: 'Verify Entries',
      validate: () => {
        return null
      },
      onSubmit: async () => {
        stepper.goToNext()
      },
    },
    judgementDetails: {
      name: 'Judgement Details',
      validate: () => {
        const newErrors = {} as any

        // validate judge id
        if (!wizardData.judge_id)
          newErrors.judge_id = 'Judge is required'

        // validate judge allowed categories
        newErrors.judges_allowed_category = wizardData.judges_allowed_category.map((jac) => {
          const errors = {} as any

          if (!jac.id)
            errors.id = 'Category is required'

          return Object.keys(errors).length ? errors : null
        })
        if (newErrors.judges_allowed_category.filter(Boolean).length === 0)
          delete newErrors.judges_allowed_category

        return Object.keys(newErrors).length ? newErrors : null
      },
      onSubmit: async () => {
        try {
          savingAdjudication.value = true
          const response = await $api.cases.adjudication.create(wizardData)
          onComplete(response.data)
        } catch (error) {
          console.error(error)
        } finally {
          savingAdjudication.value = false
        }
      },
    },
  })

  const updateCaseLocally = (caseId: IAdjudicationCase['id'], data: Partial<IAdjudicationCase> ) => {
    const _case = useFind(wizardData.cases, { id: caseId }) as IAdjudicationCase

    if (_case) {
      useFindAndUpdate(wizardData.cases, 'id', {
        ..._case,
        ...data,
      })
    }
  }

  const onSubmit = () => {
    const { validate, onSubmit } = stepper.current.value

    errors.value = validate()

    if (errors.value)
      return

    onSubmit()
  }

  const previousStep = () => {
    stepper.goToPrevious()

    errors.value = []
  }

  const findCategoryOption = (categoryName: string) => {
    return allowedCategories.value.data.find(c => c.name === categoryName)
  }

  function addCategory(name = '') {
    wizardData.judges_allowed_category.push({
      id: '',
      name,
      note: '',
    })
  }

  function removeCategory(index: number) {
    wizardData.judges_allowed_category.splice(index, 1)
  }

  function addCategoryByName(categoryName: string) {
    const category = findCategoryOption(categoryName)

    if (!category)
      return

    wizardData.judges_allowed_category.push({
      id: category.id,
      name: category.name,
      note: '',
    })
  }

  async function loadJudges() {
    judges.value.loading = true
    try {
      const response = await $api.judges.list({})
      judges.value.data = response.data
    } catch (error) {
      console.log(error)
    } finally {
      judges.value.loading = false
    }
  }

  async function loadAllowedCategories() {
    allowedCategories.value.loading = true
    try {
      const response = await $api.allowedCategories.list({})
      allowedCategories.value.data = response.data

      // set the first two categories ("Court Cost" & "Attorney Fees") as default
      addCategoryByName(COURT_COST_CATEGORY)
      addCategoryByName(ATTORNEY_FEES_CATEGORY)
    } catch (error) {
      // 
    } finally {
      allowedCategories.value.loading = false
    }
  }

  onMounted(() => {
    loadJudges()
    loadAllowedCategories()
  })

  $api.allowedCategories.on('created', () => loadAllowedCategories())

  return {
    savingAdjudication,
    stepper,
    wizardData,
    judges,
    allowedCategories,
    errors,
    //
    previousStep,
    onSubmit,
    updateCaseLocally,
    addCategory,
    removeCategory,
    addCategoryByName,
  }
}

export const useCaseAdjudicationWizardForm = createSharedComposable(useCaseAdjudicationWizardFormComposable)
