import { DateTime } from 'luxon'
import { useForm } from 'vee-validate'
import { object, string } from 'yup'
import type {
  Case,
  CaseLedgerItem,
  UpdateCaseLedgerItem,
} from '~/repository/modules'

function useEditCaseChargeFormComposable(
  caseId: Case['id'],
  charge: CaseLedgerItem,
) {
  const { $api } = useNuxtApp()

  const loading = ref<boolean>(false)

  const { handleSubmit, resetForm } = useForm<UpdateCaseLedgerItem>({
    validationSchema: object({
      charge_date: string().required(),
      amount: string()
        .required()
        .test(
          'is-money',
          'Amount must be a valid format e.g. 100.00',
          (value) => {
            return /^\d+(\.\d{1,2})?$/.test(value as unknown as string)
          },
        ),
      organization_chart_of_accounts_id: string().required(),
      description: string(),
    }),
  })

  const onSubmit = handleSubmit((params) => {
    loading.value = true
    $api.cases.ledger
      .updateCharge(caseId, charge.id, params)
      .finally(() => (loading.value = false))
  })

  nextTick(() => {
    resetForm({
      values: {
        charge_date: DateTime.fromISO(charge.charge_date!),
        amount: charge.amount!,
        description: charge.description,
        organization_chart_of_accounts_id:
          charge.organization_chart_of_accounts_id,
      },
    })
  })

  return {
    loading,
    onSubmit,
  }
}

export const useEditCaseChargeForm = createSharedComposable(
  useEditCaseChargeFormComposable,
)
