<script setup lang="ts">
import { Bars3Icon } from '@heroicons/vue/24/outline'
import { storeToRefs } from 'pinia'
import { useSidebarStore } from '~/sites/dashboard/stores/sidebar'

const { sidebarOpen } = storeToRefs(useSidebarStore())

function openSidebar() {
  sidebarOpen.value = true
}
</script>

<template>
  <button type="button" class="-m-2.5 p-2.5 text-gray-700 xl:hidden" @click="openSidebar">
    <span class="sr-only">Open sidebar</span>
    <Bars3Icon class="h-6 w-6" aria-hidden="true" />
  </button>
</template>
