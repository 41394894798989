import { DateTime } from 'luxon'
import { useForm } from 'vee-validate'
import { array, bool, string } from 'yup'
import * as ics from 'ics'
import { storeToRefs } from 'pinia'
import type { Case, CaseSummary, SaveEvictionWizardData } from '~/repository/modules'

function useCaseEvictionWizardFormComposable(caseIds: Array<Case['id']>) {
  const { $api } = useNuxtApp()
  const casesStore = useCasesStore()
  const { cases } = storeToRefs(casesStore)

  const loading = ref<boolean>(false)

  const { handleSubmit, resetForm } = useForm<SaveEvictionWizardData>({
    validationSchema: {
      case_ids: array().of(string()).min(1),
      move_to_next: bool().required(),
      generate_calendar_event: bool().required(),
      eviction_date: string().required(),
    },
    initialValues: {
      case_ids: [],
      move_to_next: false,
      generate_calendar_event: false,
      eviction_date: '',
    },
  })

  const generateCalendarAllDayDate = (date: Date) => {
    // slice the first 3 elements to get the year, month, and day
    // ref: https://www.npmjs.com/package/ics search `all-day event` to find this usage
    return ics.convertTimestampToArray(date.getTime(), 'local').slice(0, 3) as ics.DateArray
  }

  const createCalendarEvent = async (values: SaveEvictionWizardData) => {
    const selectedCases = values.case_ids.map((caseId: Case['id']) => cases.value.find((c: CaseSummary) => c.id === caseId)) as CaseSummary[]

    const evictionStartDate = DateTime.fromISO(values.eviction_date as string).toJSDate()
    const evictionEndDate = DateTime.fromISO(values.eviction_date as string).plus({ days: 1 }).toJSDate()

    const evictionDateString = DateTime.fromISO(values.eviction_date as string).toFormat('M-d-yyyy')

    const evictionStartDateArray = generateCalendarAllDayDate(evictionStartDate)
    const evictionEndDateArray = generateCalendarAllDayDate(evictionEndDate)

    const eventDescription = selectedCases.map((c: CaseSummary) => {
      const headOfHousehold = c.residents[0]?.full_name
      const streetAddress = c.unit_address_line_1
      const unit = c.unit_address_line_2
      const linkToCase = `${window.location.origin}/cases/${c.id}`
      const addressHyperlink = `${streetAddress}${unit ? `, ${unit}` : ''}`

      return `• ${headOfHousehold} ${addressHyperlink} <${linkToCase}>`
    }).join('\n\n')

    const event: ics.EventAttributes = {
      title: 'Scheduled Evictions',
      description: eventDescription,
      start: evictionStartDateArray,
      end: evictionEndDateArray,
    }

    const filename = `ScheduledEvictions_${evictionDateString}.ics`
    const file = await new Promise((resolve, reject) => {
      ics.createEvent(event, (error, value) => {
        if (error)
          reject(error)

        resolve(new File([value], filename, { type: 'text/calendar' }))
        loading.value = false
      })
    })
    const url = URL.createObjectURL(file as File)

    // trying to assign the file URL to a window could cause cross-site
    // issues so this is a workaround using HTML5
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = filename

    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)

    URL.revokeObjectURL(url)
  }

  const onSubmit = handleSubmit(async (values) => {
    loading.value = true

    values.move_to_next = +values.move_to_next

    if (values.eviction_date instanceof Date)
      values.eviction_date = DateTime.fromJSDate(values.eviction_date).toISODate()!

    $api.cases.evictionWizard.save(values)
      .then(() => {
        if (values.generate_calendar_event)
          createCalendarEvent(values)
      })
      .finally(() => loading.value = false)
  })

  resetForm({
    values: {
      case_ids: caseIds,
    },
  })

  return {
    loading,
    onSubmit,
  }
}

export const useCaseEvictionWizardForm = createSharedComposable(useCaseEvictionWizardFormComposable)
