import type { SearchableParams } from '~/repository/factory'
import type { Case, Lease, LeaseTransaction } from '~/repository/modules'
import type { ComboboxOption } from '~/ui/components/Combobox/BaseCombobox.vue'

function useLeaseChargesSelectComposable(leaseId: Lease['id'], caseId?: Case['id']) {
  const { $api } = useNuxtApp()

  const loading = ref<boolean>(false)
  const charges = ref<LeaseTransaction[]>([])

  const list = (params: SearchableParams = {}) => {
    loading.value = true
    $api.leases.transactions.listCharges(leaseId, params, caseId)
      .then((response) => {
        charges.value = response.data
      })
      .finally(() => loading.value = false)
  }

  const selectList = computed(() => {
    return charges.value.map((fee: LeaseTransaction) => {
      return {
        label: `[${fee.date}] - (${fee.account_number ?? 'Unknown Account'} ${fee.account_description}) - ${fee.amount} (${fee.description})`,
        value: fee.id,
        service_from: fee.service_from,
      } as ComboboxOption
    })
  })

  list()

  return {
    loading,
    selectList,
    list,
  }
}

export const useLeaseChargesSelect = createSharedComposable(useLeaseChargesSelectComposable)
