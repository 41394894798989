import { DateTime } from 'luxon'
import { useForm } from 'vee-validate'
import { array, object, string } from 'yup'
import type { Case, SaveChangeEvictionDateData } from '~/repository/modules'

function useCaseChangeEvictionDateFormComposable(caseIds: Array<Case['id']>) {
  const { $api } = useNuxtApp()

  const loading = ref<boolean>(false)

  const { handleSubmit, resetForm } = useForm<SaveChangeEvictionDateData>({
    validationSchema: object({
      case_ids: array().of(string()).min(1),
      eviction_date: string().required(),
    }),
  })

  const onSubmit = handleSubmit((params) => {
    loading.value = true

    if (params.eviction_date instanceof Date)
      params.eviction_date = DateTime.fromJSDate(params.eviction_date).toISODate()!

    $api.cases.changeEvictionDate.save(params)
      .finally(() => loading.value = false)
  })

  resetForm({
    values: {
      case_ids: caseIds,
    },
  })

  return {
    loading,
    onSubmit,
  }
}

export const useCaseChangeEvictionDateForm = createSharedComposable(useCaseChangeEvictionDateFormComposable)
