import { useForm } from 'vee-validate'
import { object, string } from 'yup'
import type { CreateLeaseCase, Lease } from '~/repository/modules'

function createLeaseCaseComposable(leaseId: Lease['id']) {
  const { $api } = useNuxtApp()

  const loading = ref<boolean>(false)

  const { handleSubmit } = useForm<CreateLeaseCase>({
    validationSchema: object().shape({
      type_of_case: string().required().label('Case Type'),
    }),
  })

  const onSubmit = handleSubmit((values) => {
    loading.value = true
    $api.leases.cases.create(leaseId, values)
      .finally(() => loading.value = false)
  })

  return {
    loading,
    onSubmit,
  }
}

export const useCreateLeaseCaseForm = createSharedComposable(createLeaseCaseComposable)
