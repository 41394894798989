<script setup lang="ts">
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'

const globalSearch = 'Global Search'
const search = ref<string>('')

const route = useRoute()
search.value = route.query.q as string
</script>

<template>
  <form class="relative flex flex-1" action="/search" method="GET">
    <label for="search-field" class="sr-only">{{ globalSearch }}</label>
    <MagnifyingGlassIcon class="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
      aria-hidden="true" />
    <input id="search-field" v-model="search"
      class="block h-full w-full xl:w-[500px] border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
      :placeholder="globalSearch" type="search" name="q">
  </form>
</template>
