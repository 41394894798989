import { Pagination } from '@packages/ui/helpers/pagination'
import { defineStore } from 'pinia'
import type { SearchableParams } from '~/repository/factory'
import type { Notification } from '~/repository/modules'

export const useNotificationsStore = defineStore('notifications', () => {
  const { $api, $toast } = useNuxtApp()
  const loading = ref<boolean>(false)
  const unreadCount = ref<number>(0)
  const notifications = ref<Notification[]>([])
  const notificationsLocalChannel = new BroadcastChannel('user-notifications')
  const pagination = ref(new Pagination({ per_page: 10 }))
  const filters = reactive<SearchableParams>({
    search: undefined,
  })

  const list = () => {
    loading.value = true
    $api.notifications.list({
      page: pagination.value.current_page,
      ...filters,
    })
      .then((response) => {
        response.data.forEach(notification => notifications.value.push(notification))
        pagination.value.update(useOmit(response.meta, 'unread_count'))
        unreadCount.value = response.meta.unread_count
      })
      .finally(() => loading.value = false)
  }

  const addNotification = (notification: Notification) => {
    notifications.value.unshift(notification)
    unreadCount.value++
  }

  const handleRealtimeNotification = (message: any) => {
    if (message.name === 'documents.batch.completed') {
      $toast.addToast({
        title: 'Documents Completed',
        withButtons: [
          {
            name: 'Download Zip File',
            href: message.data?.data?.zip_file,
          },
        ],
        type: 'success',
      })
      return
    }

    if (message.name.includes(".failed")){
      $toast.addToast({
        title: 'Error',
        message: message.data?.data?.message,
        type: 'danger',
      })
      return
    }

    addNotification({
      id: message.id,
      data: message.data,
      created_at: new Date().toDateString(),
    } as Notification)
  }

  notificationsLocalChannel.onmessage = (message) => {
    if (message.data.type === 'user-notification')
      handleRealtimeNotification(message.data.data)
  }

  const reset = () => {
    notifications.value = []
    pagination.value.reset()
    unreadCount.value = 0
    list()
  }

  $api.notifications.on('read', (notification) => {
    useFindAndUpdate(notifications, 'id', notification)

    if (unreadCount.value && unreadCount.value > 0)
      unreadCount.value--
  })

  return {
    notifications,
    notificationsLocalChannel,
    pagination,
    loading,
    unreadCount,
    filters,
    addNotification,
    handleRealtimeNotification,
    list,
    reset,
  }
})
