import { useForm } from 'vee-validate'
import { mixed, object, string } from 'yup'
import { ImportChartOfAccountsSheet } from '~/repository/modules/organizations/chartOfAccounts'

function createChartOfAccountsImportComposable() {
  const { $api } = useNuxtApp()

  const loading = ref<boolean>(false)

  const { handleSubmit, errors, setFieldValue } = useForm<ImportChartOfAccountsSheet>({
    validationSchema: object({
      organizationId: string().required(),
      sheet: mixed().required(),
    }),
  })

  const onSubmit = handleSubmit((values) => {
    loading.value = true

    $api.organization.chart_of_accounts.import(values)
      .finally(() => {
        loading.value = false
      })
  })

  return {
    loading,
    errors,
    setFieldValue,
    onSubmit,
  }
}

export const useChartOfAccountsImportForm = createSharedComposable(createChartOfAccountsImportComposable)
