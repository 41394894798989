import { useForm } from 'vee-validate'
import { object, string } from 'yup'
import type { CreateLeasePayment, Lease } from '~/repository/modules'

function useCreateLeasePaymentComposable(leaseId: Lease['id']) {
  const { $api } = useNuxtApp()

  const loading = ref<boolean>(false)

  const { handleSubmit, setFieldValue } = useForm<CreateLeasePayment>({
    validationSchema: object({
      payment_date: string().required(),
      service_from: string().required(),
      amount: string().required().test(
        'is-money',
        'Amount must be a valid format e.g. 100.00',
        (value) => {
          return /^\d+(\.\d{1,2})?$/.test(value as unknown as string)
        },
      ),
      account: string().required(),
      note: string().nullable(),
      reference_number: string().nullable(),
      ledger_charges_id: string().required(),
    }),
  })

  const onSubmit = handleSubmit((values) => {
    loading.value = true
    $api.leases.transactions.createPayment(leaseId, values)
      .finally(() => loading.value = false)
  })

  return {
    loading,
    onSubmit,
    setFieldValue,
  }
}

export const useCreateLeasePaymentForm = createSharedComposable(useCreateLeasePaymentComposable)
